import ContentBlock from "./ContentBlock";
import Footer from "./Footer";
import Header from "./Header";
import HeroBlock from "./HeroBlock";
// import PostsBlock from './PostsBlock';
import ProjectsBlock from "./ProjectsBlock";
import Social from "./Social";
import Layout from "./Layout";
import DateRange from "./DateRange";
import Employer from "./Employer";

export {
  ContentBlock,
  Footer,
  Header,
  HeroBlock,
  // PostsBlock,
  ProjectsBlock,
  Social,
  Layout,
  DateRange,
  Employer
};

export default {
  ContentBlock,
  Footer,
  Header,
  HeroBlock,
  // PostsBlock,
  ProjectsBlock,
  Social,
  Layout,
  DateRange,
  Employer
};
