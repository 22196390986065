import React from "react";
import _ from "lodash";
import deLocale from "moment/locale/de";
import moment from "moment-strftime";
import { getAccessLevel } from "../utils";

export default class DateRange extends React.Component {
  // does not help - see: https://reactjs.org/docs/shallow-compare.html
  // shouldComponentUpdate(nextProps, nextState) {
  //   return shallowCompare(this, nextProps, nextState);
  // }

  render() {
    let level = getAccessLevel("level");
    if (level < 2) {
      return <span></span>;
    }
    // https://momentjs.com/guides/#/warnings/define-locale/
    // moment.locale("de", deLocale);
    moment.updateLocale("de", deLocale);
    return (
      <span>
        <time
          className="project-timerange"
          dateTime={moment(_.get(this.props, "frontmatter.start")).strftime(
            "%Y-%m-%d"
          )}
        >
          {this.props.children}
          {/* single day project */}
          {_.get(this.props, "frontmatter.end") &&
            _.get(this.props, "frontmatter.start") ===
              _.get(this.props, "frontmatter.end") &&
            moment(_.get(this.props, "frontmatter.start")).strftime("%d %B %Y")}

          {/* still running project */}
          {!_.get(this.props, "frontmatter.end") && "seit "}

          {/* same year project */}
          {moment(_.get(this.props, "frontmatter.start")).strftime("%Y") ===
            moment(_.get(this.props, "frontmatter.end")).strftime("%Y") &&
            _.get(this.props, "frontmatter.start") !==
              _.get(this.props, "frontmatter.end") &&
            moment(_.get(this.props, "frontmatter.start")).strftime("%B")}

          {/* start date with year */}
          {moment(_.get(this.props, "frontmatter.start")).strftime("%Y") !==
            moment(_.get(this.props, "frontmatter.end")).strftime("%Y") &&
            moment(_.get(this.props, "frontmatter.start")).strftime("%B %Y")}
        </time>
        {/* end date with year only if not single day */}
        {_.get(this.props, "frontmatter.end") &&
          _.get(this.props, "frontmatter.start") !==
            _.get(this.props, "frontmatter.end") && (
            <time
              className="project-timerange"
              dateTime={moment(_.get(this.props, "frontmatter.end")).strftime(
                "%Y-%m-%d"
              )}
            >
              {" "}
              - {moment(_.get(this.props, "frontmatter.end")).strftime("%B %Y")}
            </time>
          )}
      </span>
    );
  }
}
