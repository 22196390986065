import React from 'react';
import _ from 'lodash';

import DateRange from '../components/DateRange';

import {htmlToReact, Link, safePrefix} from '../utils';

export default class Employer extends React.Component {
    render() {
        // let display_projects = _.orderBy(getPages(this.props.pageContext.pages, '/projects'), 'frontmatter.start', 'desc');

        // let display_projects = _.orderBy(getPages(this.props.pageContext.pages, '/projects'),['frontmatter.highlight',
        //   function(object) {
        //     return new Date(object.frontmatter.start);
        //   }],['asc', 'desc'])

        // let recent_projects = display_projects.slice(0, _.get(this.props, 'section.num_projects_displayed'));
        // console.log(this.props.pages);
        // console.log(this.props.workplace);

        // src/pages/work/qnipp.md -> work/qnipp.md
        // console.log(this.props.pageContext);

        return (
          <article className="project project-full">
            <header className="project-header">
              <h1 className="project-title underline">
                {_.get(this.props, "pageContext.frontmatter.company")}
              </h1>
            </header>
            {_.get(this.props, "pageContext.frontmatter.jobtitle") && (
              <div className="project-subtitle">
                {htmlToReact(
                  _.get(this.props, "pageContext.frontmatter.jobtitle")
                )}
              </div>
            )}
            <div className="project-upper">
              <div className="project-content">
                <p>{_.get(this.props, "pageContext.frontmatter.excerpt")}</p>

                {_.get(this.props, "pageContext.frontmatter.tools") && (
                  <cite>
                    {"Tools und Methoden: " +
                      htmlToReact(
                        _.get(this.props, "pageContext.frontmatter.tools")
                      )}
                  </cite>
                )}
                {_.get(this.props, "pageContext.frontmatter.company_url") && (
                  <p>
                    <br />
                    <Link
                      to={safePrefix(
                        _.get(this.props, "pageContext.frontmatter.company_url")
                      )}
                      target="_blank"
                      rel="noopener"
                    >
                      {_.get(this.props, "pageContext.frontmatter.company")}
                    </Link>
                  </p>
                )}
              </div>
              {_.get(
                this.props,
                "pageContext.frontmatter.content_img_path"
              ) && (
                <div className="project-thumbnail">
                  <img
                    src={safePrefix(
                      _.get(
                        this.props,
                        "pageContext.frontmatter.content_img_path"
                      ).replace(/\.png|\.jpg$/, ".webp")
                    )}
                    alt={_.get(this.props, "pageContext.frontmatter.company")}
                  />
                </div>
              )}
            </div>
            <div className="project-content">
              {htmlToReact(_.get(this.props, "pageContext.html"))}
            </div>
            <footer className="project-meta">
              <DateRange {...this.props.pageContext}>Angestellt </DateRange>
              {_.get(this.props, "pageContext.frontmatter.location") &&
                " in " + _.get(this.props, "pageContext.frontmatter.location")}
            </footer>
          </article>
        );
    }
}
