const { withPrefix } = require("gatsby");
const _ = require("lodash");

export default function (url) {
  if (_.startsWith(url, "#")) {
    return url;
  }
  // remove http:// and www from url
  url = url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "");
  // remove trailing slash
  return url.replace(/\/$/, "");
}
