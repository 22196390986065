// get current accesslevel, get current name
import Crypt from './crypt';

export default function (key = "level") {
  let level = 0;
  let name = "visitor";

  if (
    typeof window !== "undefined" &&
    window.localStorage &&
    window.localStorage.getItem("level")
  ) {
    let crypt = new Crypt();
    let level_raw = window.localStorage.getItem("level");
    if (level_raw.length >= 32) {
      level = parseInt(crypt.decrypt(level_raw));
      name = crypt.decrypt(window.localStorage.getItem("name"));
    }
  }
  if (key === "level") {
    return level;
  } else if (key === "name") {
    return name;
  } else {
    return [level, name];
  }
}
