// see: https://marked.js.org/
// import marked from 'marked';
import { marked } from 'marked';

import htmlToReact from './htmlToReact';

export default function(markdown) {
    if (!markdown) {
        return null;
    }
//     return htmlToReact(marked(markdown));
    return htmlToReact(marked.parse(markdown));
};
