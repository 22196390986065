import React from 'react';
import _ from 'lodash';

import DateRange from './DateRange';

import {getPages, Link, safePrefix} from '../utils';

export default class ProjectsBlock extends React.Component {
    render() {
        let display_projects = _.orderBy(
          getPages(this.props.pageContext.pages, "/projects"),
          [
            function (object) {
              return object.frontmatter.highlight ? 1 : 0;
            },
            function (object) {
              if (object.frontmatter.end) {
                return new Date(object.frontmatter.end).getTime();
              } else {
                return new Date().getTime();
              }
            },
            function (object) {
              return new Date(object.frontmatter.start).getTime();
            },
          ],
          ["desc", "desc", "desc"]
        );

        let recent_projects = display_projects.slice(0, _.get(this.props, 'section.num_projects_displayed'));
        return (
          <section
            id={_.get(this.props, "section.section_id")}
            className="block"
          >
            <h2 className="block-title underline">
              {_.get(this.props, "section.title")}
            </h2>
            <div className="project-feed">
              {_.map(recent_projects, (project, project_idx) => (
                <article key={project_idx} className="project">
                  <div className="project-inside">
                    {_.get(project, "frontmatter.thumb_img_path") && (
                      <Link
                        className="project-thumbnail"
                        to={safePrefix(_.get(project, "url"))}
                      >
                        <img
                          className="thumbnail"
                          src={safePrefix(
                            _.get(
                              project,
                              "frontmatter.thumb_img_path"
                            ).replace(/\.png|\.jpg$/, ".webp")
                          )}
                          alt={_.get(project, "frontmatter.title")}
                        />
                      </Link>
                    )}
                    <header className="project-header">
                      <h3 className="project-title">
                        <Link
                          to={safePrefix(_.get(project, "url"))}
                          rel="bookmark"
                        >
                          {_.get(project, "frontmatter.title")}
                        </Link>
                      </h3>
                    </header>
                    <div className="project-content">
                      <cite className="citeblock">
                        {_.get(project, "frontmatter.subtitle")}
                      </cite>
                    </div>
                    <footer className="project-meta">
                      <DateRange {...project}></DateRange>
                    </footer>
                  </div>
                </article>
              ))}
            </div>
            {_.get(this.props, "section.actions") && (
              <p className="block-cta">
                {_.map(
                  _.get(this.props, "section.actions"),
                  (action, action_idx) => (
                    <Link
                      key={action_idx}
                      to={safePrefix(_.get(action, "url"))}
                      className="button"
                    >
                      {_.get(action, "label")}
                    </Link>
                  )
                )}
              </p>
            )}
          </section>
        );
    }
}
