import _ from "lodash";

export default function(pages, folderPath) {
    // TODO: resolve relative paths relative to current page
    folderPath = folderPath.replace(/^\//, '');
    // DONE: check for enabled flag
    return _.filter(pages, function(page) {
        return page.relativeDir === folderPath && (!page.frontmatter.hasOwnProperty('enabled') || page.frontmatter.enabled)
    });

    // return _.filter(pages, {relativeDir: folderPath});
}
